var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-gradient-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-white p-4" }, [
                  _c("h5", { staticClass: "text-white" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Redem-Konto wiederherstellen")) + " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("newPassword.header.description"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-5 align-self-end" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/new-password-image.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-body pt-0" },
            [
              _c("div", [
                _c("div", { staticClass: "avatar-md profile-user-wid mb-4" }, [
                  _c(
                    "span",
                    { staticClass: "avatar-title rounded-circle bg-light" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/Redem Icon.png"),
                          alt: "",
                          height: "38",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "success" },
                  model: {
                    value: _vm.setPasswordSuccess,
                    callback: function ($$v) {
                      _vm.setPasswordSuccess = $$v
                    },
                    expression: "setPasswordSuccess",
                  },
                },
                [
                  _vm._v(
                    "Passwort erfolgreich geändert! Sie können sich nun wieder anmelden."
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.isNewPasswordError,
                    callback: function ($$v) {
                      _vm.isNewPasswordError = $$v
                    },
                    expression: "isNewPasswordError",
                  },
                },
                [_vm._v(_vm._s(_vm.newPasswordError))]
              ),
              !_vm.setPasswordSuccess
                ? _c(
                    "b-form",
                    {
                      staticClass: "p-2",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.clickNewPasswordSubmit.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-1",
                            label: this.$t(
                              "newPassword.form.newPassword.label"
                            ),
                            "label-for": "input-1",
                          },
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.submit && _vm.$v.newPassword.$error,
                            },
                            attrs: {
                              id: "input-1",
                              type: "password",
                              placeholder: this.$t(
                                "newPassword.form.newPassword.placeholder"
                              ),
                            },
                            model: {
                              value: _vm.newPassword,
                              callback: function ($$v) {
                                _vm.newPassword = $$v
                              },
                              expression: "newPassword",
                            },
                          }),
                          _vm.submit && _vm.$v.newPassword.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.newPassword.required
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "newPassword.form.newPassword.requiredErrorMsg"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.$v.newPassword.minLength
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "newPassword.form.newPassword.minLengthErrorMsg"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-2",
                            label: this.$t(
                              "newPassword.form.confirmPassword.label"
                            ),
                            "label-for": "input-2",
                          },
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.submit && _vm.$v.confirmPassword.$error,
                            },
                            attrs: {
                              id: "input-2",
                              type: "password",
                              placeholder: this.$t(
                                "newPassword.form.confirmPassword.placeholder"
                              ),
                            },
                            model: {
                              value: _vm.confirmPassword,
                              callback: function ($$v) {
                                _vm.confirmPassword = $$v
                              },
                              expression: "confirmPassword",
                            },
                          }),
                          _vm.submit && _vm.$v.confirmPassword.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.confirmPassword.required
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "newPassword.form.confirmPassword.requiredError"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-block",
                              attrs: { type: "submit", variant: "success" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("newPassword.form.continueButton")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.setPasswordSuccess
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-block",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectToLogin()
                            },
                          },
                        },
                        [_vm._v("Klicken Sie hier zum Anmelden")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c("p", [
            _vm._v(
              " © " +
                _vm._s(new Date().getFullYear()) +
                " Redem GmbH | made with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" in Austria "),
          ]),
        ]),
        _c("div", { staticClass: "mt-3 text-center" }, [
          _c(
            "span",
            {
              staticClass: "mr-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[0].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[0].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
            ]
          ),
          _vm._v(" • "),
          _c(
            "span",
            {
              staticClass: "ml-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[1].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[1].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }