<script>
import Layout from "@/router/layouts/auth";
import appConfig from "@/app.config";
import AuthService from "@/api/services/auth.service.js";
import ResponseStatus from "@/shared/enums/responseStatus.js";
import { authMethods } from "@/state/helpers";
import { required, minLength } from "vuelidate/lib/validators";
import i18n from "@/i18n";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";

/**
 * Login component
 */
export default {
  page: {
    title: "New Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      newPassword: "",
      confirmPassword: "",
      tryingToSetPassword: false,
      submit: false,
      newPasswordError: null,
      isNewPasswordError: false,
      setPasswordSuccess: false,
      token: null
    };
  },
  validations: {
    newPassword: { required, minLength: minLength(8) },
    confirmPassword: { required }
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    clickNewPasswordSubmit() {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();

      // if validation success
      if (this.$v.$invalid) {
        this.newPasswordError = this.$t("newPassword.form.errors.validation");
        this.isNewPasswordError = true;
      } else {
        this.newPasswordError = null;
        const newPassword = this.newPassword;
        const confirmPassword = this.confirmPassword;

        if (newPassword !== confirmPassword) {
          this.newPasswordError = this.$t("newPassword.form.errors.notMatch");
          this.isNewPasswordError = true;
        } else {
          this.token = this.$route.query.prt;
          this.setNewPassword();
        }
      }
    },
    async setNewPassword() {
      this.tryingToSetPassword = true;
      // Reset the authError if it existed.
      this.newPasswordError = null;
      const response = await AuthService.setNewPassword(
        this.token,
        this.newPassword
      );
      if (response?.status === ResponseStatus.SUCCESS) {
        this.setPasswordSuccess = true;
      } else {
        this.isNewPasswordError = true;
        const errorCode = response.extensions.code;
        this.newPasswordError = getErrorMessageByErrorCode(errorCode);
      }
    },
    redirectToLogin() {
      this.$router.push("/login");
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-gradient-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-white p-4">
                  <h5 class="text-white">
                    {{ $t("Redem-Konto wiederherstellen") }}
                  </h5>
                  <p>{{ $t("newPassword.header.description") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/new-password-image.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/Redem Icon.png" alt height="38" />
                </span>
              </div>
            </div>

            <b-alert v-model="setPasswordSuccess" class="mt-3" variant="success"
              >Passwort erfolgreich geändert! Sie können sich nun wieder
              anmelden.</b-alert
            >

            <b-alert
              v-model="isNewPasswordError"
              variant="danger"
              class="mt-3"
              dismissible
              >{{ newPasswordError }}</b-alert
            >

            <b-form
              class="p-2"
              @submit.prevent="clickNewPasswordSubmit"
              v-if="!setPasswordSuccess"
            >
              <b-form-group
                id="input-group-1"
                :label="this.$t('newPassword.form.newPassword.label')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="newPassword"
                  type="password"
                  :placeholder="
                    this.$t('newPassword.form.newPassword.placeholder')
                  "
                  :class="{ 'is-invalid': submit && $v.newPassword.$error }"
                ></b-form-input>
                <div
                  v-if="submit && $v.newPassword.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.newPassword.required">{{
                    $t("newPassword.form.newPassword.requiredErrorMsg")
                  }}</span>
                  <span v-if="!$v.newPassword.minLength">{{
                    $t("newPassword.form.newPassword.minLengthErrorMsg")
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                :label="this.$t('newPassword.form.confirmPassword.label')"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="confirmPassword"
                  type="password"
                  :placeholder="
                    this.$t('newPassword.form.confirmPassword.placeholder')
                  "
                  :class="{ 'is-invalid': submit && $v.confirmPassword.$error }"
                ></b-form-input>
                <div
                  v-if="submit && $v.confirmPassword.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.confirmPassword.required">{{
                    $t("newPassword.form.confirmPassword.requiredError")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-3">
                <b-button type="submit" variant="success" class="btn-block">{{
                  $t("newPassword.form.continueButton")
                }}</b-button>
              </div>
            </b-form>

            <div v-if="setPasswordSuccess">
              <b-button
                variant="success"
                class="btn-block"
                @click="redirectToLogin()"
                >Klicken Sie hier zum Anmelden</b-button
              >
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} Redem GmbH | made with
            <i class="mdi mdi-heart text-danger"></i> in Austria
          </p>
        </div>

        <!-- language Selector -->
        <div class="mt-3 text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor:pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor:pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
